<template>
  <div>
    <div class="inner_head">
      <img class="head_img" src="../../statics/images/company/detailCompany.png" alt="">
    </div>
    <div class="current_menu">
      <div class="content">
        <div class="page_indicate">
          <div class="img" />
          <span>控股企业<span> > </span>{{ info.title }}</span>
        </div>
        <ul class="page_menu">
          <li
            v-for="(item,index) in menuList"
            :key="index"
            :class="{'active': index === menuActive}"
            @click="menuSelect(item,index)"
          >
            {{ item.name }}
            <div class="menu_block" />
          </li>
        </ul>
      </div>
    </div>
    <div class="page">
      <div v-if="info.body" class="ql-editor" v-html="info.body" />
      <div v-for="(item,index) in contentArr" :key="index">
        <div v-if="item.flag === '5'" class="text_img">
          <p class="title">{{ item.title }}</p>
          <p class="text">{{ item.remark }}</p>
          <img v-if="item.iconUrl" :src="baseUrl + item.iconUrl" alt="">
        </div>
        <div v-if="item.flag === '17'" class="img_text">
          <img v-if="item.iconUrl" :src="baseUrl + item.iconUrl" alt="">
          <p class="title">{{ item.title }}</p>
          <p class="text">{{ item.remark }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getMainContent, getMenu } from '@/plugin/api'

export default {
  data() {
    return {
      baseUrl: '/website',
      info: {},
      contentArr: [],
      menuList: [],
      menuActive: 0
    }
  },
  watch: {
    '$route': {
      handler(newVal) {
        if (newVal) this.routeChange()
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    routeChange() {
      window.scrollTo({
        top: 0
      })
      const url = this.$route.query.url
      const urlType = this.$route.query.urlType
      const num = this.menuList.findIndex(item => {
        return item.url === url
      })
      this.menuActive = num < 0 ? 0 : num
      this.menuTitle = this.menuList[this.menuActive].name
      this.getInfo(url, urlType)
    },
    menuSelect(item, index) {
      this.menuActive = index
      this.menuTitle = item.name
      this.$router.push({ path: '/xljrDetailCompany', query: { url: item.url, urlType: item.urlType }})
    },
    init() {
      getMenu({ tenantId: 'xljr' }).then(res => {
        const menu = res.data.data.menuList.find(item => {
          return item.name === '控股企业'
        })
        this.menuList = menu.children
        this.routeChange()
      })
    },
    getInfo(url, urlType) {
      getMainContent({ tenantId: 'xljr', url, urlType }).then(res => {
        this.info = res.data.data.contentMainList[0]
        this.contentArr = this.info.contentItemTreeList
        console.log(this.info)
        console.log(this.contentArr)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .ql-editor{
    p{
      white-space: pre-wrap;
    }
    img{
      max-width: 100%;
      display: block;
      margin: 0 auto;
    }
  }
.page{
  width: 1200px;
  margin: 20px auto;
  background-color: #fff;
  padding: 35px 50px;
  text-align: left;
  .text_img,.img_text{
    margin-bottom: 20px;
  }
  img{
    max-width: 100%;
    margin: 10px 0 10px 0;
  }
  .title{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .text{
    white-space: pre-wrap;
    margin-bottom: 40px;
  }
}
</style>
